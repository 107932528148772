import React from "react"
import {graphql} from 'gatsby'
import Layout from 'src/components/layout'
import {getPageData} from 'src/utilities/data'
import SEO from 'src/components/seo'
import { LandingPage as PageQuery, LandingPageSEO, Link, Menu, Meta, SiteSettings, SiteBanner } from 'src/queries'
import Slices from 'src/components/slices'
import Instagram from 'src/components/instagram'

const LandingPage = getPageData(({pageData, menuData, siteBannerData, siteSettings, pageContext}) => {
  // ContentAreas up to a total of 100% will be moved inside the nearest ContentAreaWrapper. If a ContentAreaWrapper is not defined, one will be created.
  const sumPercent = (sum, { primary: { width } }) => (sum + parseFloat(((width || '100').toString()).replace(/\D/g,'')))
  const slices = (pageData.body || []).reduce((slices, item) => {
    const previous = slices[slices.length - 1] || {}
    if (item.type === 'content_area_wrapper') {
      slices.push({...item, content_areas: []})
    } else if (item.type !== 'content_area') {
      slices.push(item)
    } else if (previous.type === 'content_area_wrapper' && [...previous.content_areas, item].reduce(sumPercent, 0) <= 100) {
      previous.content_areas.push(item)
    } else {
      slices.push({ type: 'content_area_wrapper', content_areas: [item]})
    }
    return slices
  }, [])

  return (
    <Layout pageContext={pageContext} menuData={menuData} siteBannerData={siteBannerData} siteSettings={siteSettings}>
      <SEO {...pageData} lang={pageContext.lang} />
      <Slices data={slices} />
      {pageData.show_instagram && <Instagram menuData={menuData} />}
    </Layout>
  )
})
LandingPage.fragments = [PageQuery, Link, LandingPageSEO, Menu, Meta, SiteSettings, SiteBanner]
export default LandingPage

export const query = graphql`
  query($uid: String, $lang: String) {
    prismic {
      allLanding_pages(uid: $uid, lang: $lang) { ...LandingPage }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
      allSite_banners(lang: $lang) { ...SiteBanner }
    }
  }
`